define("discourse/plugins/discourse-blizzard-plugin/admin/templates/connectors/admin-user-below-names/blizzard-fields", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="display-row bnet-id">
    <div class="field">
      Battle.net ID
    </div>
    <div class="value">
      {{dash-if-empty user.bnet_id}}
    </div>
  </div>
  
  <div class="display-row battletag">
    <div class="field">
      BattleTag
    </div>
    <div class="value">
      {{dash-if-empty user.battletag}}
    </div>
  </div>
  */
  {
    "id": "7uAXUgKZ",
    "block": "[[[10,0],[14,0,\"display-row bnet-id\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n    Battle.net ID\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"value\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[[30,0,[\"user\",\"bnet_id\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"display-row battletag\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n    BattleTag\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"value\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[[30,0,[\"user\",\"battletag\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `user` property path was used in the `discourse/plugins/discourse-blizzard-plugin/admin/templates/connectors/admin-user-below-names/blizzard-fields.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.user}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `user` property path was used in the `discourse/plugins/discourse-blizzard-plugin/admin/templates/connectors/admin-user-below-names/blizzard-fields.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.user}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"dash-if-empty\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/admin/templates/connectors/admin-user-below-names/blizzard-fields.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});