define("discourse/plugins/discourse-blizzard-plugin/components/category-realm-checkbox", ["exports", "@ember/component", "I18n"], function (_exports, _component, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "",
    content: [{
      id: null,
      name: _I18n.default.t("realms.select.not")
    }, {
      id: "true",
      name: _I18n.default.t("realms.select.normal")
    }, {
      id: "classic",
      name: _I18n.default.t("realms.select.classic")
    }]
  });
});